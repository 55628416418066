import axios from "axios";

const request = axios.create({
  baseURL: `https://admin.gdhhsh.cn`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // 响应错误时处理
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // 响应的数据
    return response;
  },
  (error) => {
    if (error && error.response) {
      // 响应错误时处理
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        this.$toast("服务器响应超时，请刷新当前页面");
      }
      error.message = "连接服务器失败";
    }
    return Promise.reject(error);
  }
);

export default request;
