import http from "@/utils/http";
const fixedPath = "https://admin.gdhhsh.cn";

// 会员风采
export function Memberstyle(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}
// 资源共享
export function resourcesharing(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}
// 需求
export function demandlist(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}

// 会员供需发布
export function supplyanddemand(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}

// 新闻接口
export function newsAPIlist(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}
// 幻灯片接口
export function SlideAPIlist(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}
// 新闻列表
export function Newslist(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}
// 怀商新闻
export function Huaishangnews(params) {
  return http.post(`${fixedPath}/cms/category/list.do`, params);
}

// 新闻详情
export function Newsdetail(params) {
  return http.get(`${fixedPath}/cms/content/get.do`, params);
}

//提交咨询、注册
export function advisoryAPI(params) {
  return http.get(`${fixedPath}/mesBoard/addMesBoard`, params);
}
//提交试用申请
export function submitApplyAPI(params) {
  return http.post(`${fixedPath}/MUser/user/add`, params);
}
//获取验证码文案
export function getRegisteredAPI(params) {
  return http.get(`${fixedPath}/MUser/code`, params);
}
//获取验手机验证码
export function getPhoneCodeAPI(params) {
  return http.get(`${fixedPath}/MUser/sendSms`, params);
}
//验证手机验证码
export function verifyPhoneCodeAPI(params) {
  return http.get(`${fixedPath}/MUser/codeVali`, params);
}
//获取用户信息
export function getUserInfo(params) {
  return http.get(`${fixedPath}/api/wpa/user/`, params);
}

//对URL后面的参数进行拼接
export function queryString(params, postFlag) {
  let paramUrl = "";
  for (let key in params) {
    if (!postFlag && paramUrl == "") {
      paramUrl += "?" + key + "=" + params[key];
    } else {
      paramUrl += "&" + key + "=" + params[key];
    }
  }
  return paramUrl;
}
