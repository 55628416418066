<template>
  <div class="containerBottom">
    <div class="topFrame">
      <div class="itemLink hidden-xs">
        <ul>
          <li>会员权益</li>
          <li @click="toMES" class="pointer">法律援助</li>
          <li @click="toMES" class="pointer">农特产品</li>
          <li @click="toMES" class="pointer">智慧城市</li>
          <li @click="toMES" class="pointer">人力资源</li>
        </ul>
        <ul>
          <li>怀商风采</li>
          <li @click="toResourceSharing" class="pointer">会员单位介绍</li>
          <li @click="toSupplyDemand" class="pointer">会员供需发布</li>
          <li @click="toUnitIntroduce" class="pointer">会员资源共享</li>
        </ul>
        <ul>
          <li>怀商概况</li>
          <li @click="toCommpany" class="pointer">怀商简介</li>
          <li @click="toIndustry" class="pointer">怀商章程</li>
        </ul>
        <ul>
          <li>怀商新闻</li>
          <li
            v-for="(item, index) in resdata"
            :key="index"
            @click="toAboutCommpany(item)"
            class="pointer"
          >
            {{ item.categoryTitle }}
          </li>
        </ul>
      </div>
      <div class="itemInfo">
        <div class="phoneLine">联系电话<b>0755-86569739</b></div>
        <div class="phoneMonday">周一至周日：8:30～18:00</div>
        <ul>
          <li><img src="../assets/kf-wx.png" alt="" /><span>联系微信</span></li>
          <li><img src="../assets/email.jpg" alt="" /><span>微信公众号</span></li>
        </ul>
      </div>
    </div>
    <div class="footerAbout">
      <div class="friens_link hidden-xs">
        <div>友情链接：(申请友链QQ：3266658887)</div>
        <div><a href="http://www.huahan.com">华瀚科技</a></div>
        <div><a href="https://www.epipe.cn/">优管网</a></div>
      </div>
      <div class="epi_part">Copyright 2017-2021 广东省湖南怀化商会版权所有</div>
      <div class="_mark">
        <ul>
          <li class="record">
            <a
              rel="external nofollow"
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502000741"
              >粤公安备&nbsp;44030502000741</a
            >
          </li>
          <li>
            <a
              rel="external nofollow"
              target="_blank"
              href="https://beian.miit.gov.cn/"
              >粤ICP&nbsp;备&nbsp;16081673号 电子公告服务规则</a
            >
          </li>
          <li>
            <a
              target="_blank"
              rel="external nofollow"
              href="http://v3.epipe.cn/Uploaded/Original/%E4%BC%98%E7%AE%A1ICPEDI%E8%AF%81%E4%B9%A6-2.jpg"
              >电信增值业务经营许可证：粤&nbsp;B2-20170236</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Qs from "qs";
import { Huaishangnews, demandlist, Newsdetail } from "@/api/fetch";

export default {
  inject: ["reload"],
  data() {
    return {
      resdata: [],
    };
  },
  mounted() {
    this.dataAPIlist();
  },
  methods: {
    dataAPIlist() {
      let obj = {
        categoryId: "1",
      };
      Huaishangnews(Qs.stringify(obj)).then((res) => {
        this.resdata = res.data.data.rows;
      });
    },

    toHome() {
      this.$router.push({
        name: "Home",
      });
    },
    toMES() {
      this.$router.push({
        name: "UnitIntroduce",
      });
    },

    // 会员单位介绍
    toResourceSharing() {
      this.$router.push({
        name: "OurStyle",
      });
    },
    // 会员供需发布
    toSupplyDemand() {
      this.$router.push({
        name: "members",
      });
    },
    // 会员资源共享
    toUnitIntroduce() {
      this.$router.push({
        name: "UnitIntroduce",
      });
    },
    // 怀商简介
    toCommpany() {
      this.$router.push({
        name: "Brief",
      });
    },
    // 怀商章程
    toIndustry() {
      this.$router.push({
        name: "Constitution",
      });
    },
    // 新闻报道
    toAboutCommpany(name_) {
      this.$router.push({
        path: `/OurNews/${name_.id}`,
      });
    },
  },
};
</script>

<style scoped lang="less">
.containerBottom {
  font-size: 12px;
  background: #333333;
  color: #ffffff;
  .center-layout {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .topFrame {
    display: flex;
    justify-content: space-around;
    .itemLink {
      width: 60%;
      display: flex;
      justify-content: space-between;
      ul {
        display: inline-block;
        li,
        a {
          font-size: 12px;
          opacity: 0.8;
          line-height: 3rem;
          color: #d1cfcf;
          a {
            color: #fff;
            text-decoration: none;
          }
        }
        li:first-child {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 10px;
          opacity: 1;
          color: #fff;
        }
        .pointer {
          cursor: pointer;
          & :hover {
            color: #d1cfcf !important;
          }
        }
      }
    }
    .itemInfo {
      text-align: right;
      .phoneLine {
        font-size: 1.4rem;
        font-weight: 500;
        b {
          font-size: 2rem;
          font-weight: normal;
          margin-left: 1.4rem;
        }
      }
      .phoneMonday {
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: 0.5rem;
      }
      ul {
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        padding: 5px 0 0 0;
        // display: flex;
        li {
          display: inline-block;
          margin: 1rem 0 0 0;
          img {
            height: 7.83rem;
            width: 7.83rem;
            margin-bottom: 1rem;
          }
          span {
            display: block;
            width: 8rem;
            text-align: center;
            font-size: 1.33rem;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }

  .footerAbout {
    margin: 10px 5px 0px 5px;
    padding-bottom: 13px;
    background-color: #282828;
    & > div {
      margin: 10px 0;
    }
    .friens_link {
      .center-layout;
      div {
        font-size: 1.3rem;
        margin: 0 30px;
        a {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }
    .foot_nav {
      ul {
        li {
          font-size: 1.3rem;
          float: left;
          a {
            text-decoration: none;
            color: #ffffff;
          }
          a::after {
            content: "|";
            margin: 0 1rem;
          }
        }
        li:last-child a::after {
          content: "";
        }
      }
    }
    .epi_part {
      font-size: 1.3rem;
      text-align: center;
    }
    ._mark {
      .center-layout;
      ul {
        margin: 0;
        li {
          font-size: 1.3rem;
          .center-layout;
          a {
            text-decoration: none;
            color: #ffffff;
          }
        }
        .record::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../assets/recordIcon.png) no-repeat center center;
          margin-right: 10px;
          position: relative;
          top: -4px;
          float: left;
        }
      }
    }
  }
}
</style>
